import React from 'react';

import { Link } from 'components/common/link/Link';
import { Props } from './ListitemFAQ.interface';

import s from './ListItemFAQ.scss';

export const FAQDropDownListItem = ({ to, children }: Props): JSX.Element => (
    <li className={s.item}>
        <Link className={s.item__link} to={to}>
            {children}
        </Link>
    </li>
);
