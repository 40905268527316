import { graphql, useStaticQuery } from 'gatsby';
import { TrustpilotSummary, Summary } from './trustpilot-banner.interface';

export default (): Summary => {
    const { trustpilotSummary } = useStaticQuery<TrustpilotSummary>(graphql`
    query {
      trustpilotSummary {
        review {
          total
          desc
          score
        }
      }
    }
  `);

    return trustpilotSummary;
};
