import React from 'react';

import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Button from 'components/common/button/Button';
import packagesHook from 'hooks/packages/packages.hook';
import pageTitleHook from 'hooks/page-title/page-title.hook';
import { useCookies } from 'react-cookie';
import { getFieldBySlug } from 'utils/directus';
import { url } from 'utils/url';

import { Props } from './BuyNow.interface';
import s from './BuyNow.scss';

export default ({ slug, isPackage = false, prices = false }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, pageTitleHook());
    const [ cookies ] = useCookies();
    const companyName = cookies[ 'company-name' ];
    const companyNameIsSet = typeof companyName !== 'undefined';
    const styles = {
        small: s.buyNow__price___small,
        prefix: s.buyNow__price___smallPrefix,
        suffix: s.buyNow__price___smallSuffix,
        strike: s.buyNow__price___discounted,
    };

    const BuyButton = (): JSX.Element => {
        if (isPackage) {
        /* tslint:disable-next-line */
            const { node } = isPackage ? packagesHook().filter(({ node }) => node.slug === slug)[ 0 ] : null;

            return (
                <>
                    { companyNameIsSet ? (
                        <>
                            { node.skip_address_services ? (
                                <Button
                                    to={url.account(`${node.checkout_path}?name=${companyName}&gle=namecheck`)}
                                    className={s.packageTable__buyButton}
                                    aria-label={`Buy our ${node.name} package now.`}
                                    role="primary"
                                >
                                Buy Now
                                </Button>
                            ) : (
                                <Button
                                    to={`/address-services/?p=${slug}`}
                                    className={s.packageTable__buyButton}
                                    aria-label={`Buy our ${node.name} package now.`}
                                    role="primary"
                                >
                                Buy Now
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button
                            to={node.namecheck_path}
                            className={s.packageTable__buyButton}
                            aria-label={`Buy our ${node.name} package now.`}
                            role="primary"
                        >
                        Buy Now
                        </Button>
                    )}
                </>
            );
        }
        return (
            <Button
                to={`${node.buy_link}`}
                className={s.pageTitle__buttons___primary}
                role="primary"
            >
            Buy Now
            </Button>
        );
    };
    return (
        <>
            {prices &&
            <div className={s.buyNow__prices}>
                <div className={`${s.buyNow__price} ${node.discounted_price ? styles.strike : ''}`}>
                    {node.upgradable && (
                        <span className={`${styles.small} ${styles.prefix}`}>
                    from
                        </span>
                    )}
                    {node.price && `£${node.price.value}`}
                    {!node.discounted_price && node.vat && (
                        <span className={`${styles.small} ${styles.suffix}`}> +VAT</span>
                    )}
                    {node.monthly || node.yearly && (
                        <span className={`${styles.small} ${s.styles.suffix}`}>
                            { node.monthly ? 'per month' : 'per year' }
                        </span>
                    )}
                </div>
                { node.discounted_price && (
                    <div className={s.buyNow__price}>
                        {node.price && `£${node.discounted_price.value}`}
                        {node.vat && <span className={`${styles.small} ${styles.suffix}`}> +VAT</span>}
                    </div>
                )}
            </div>}
            {node.buttons.length > 0 && (
                <ButtonGroup className={s.buyNow__buttons}>
                    {node.buttons.indexOf('Buy Now') !== -1 && (
                        <BuyButton />
                    )}
                    {node.buttons.indexOf('View Packages') !== -1 && (
                        <Button
                            className={s.buyNow__buttons___secondary}
                            role="secondary"
                            to="/compare-packages/"
                        >
                            View Packages
                        </Button>
                    )}
                </ButtonGroup>
            )}
        </>
    );
};
