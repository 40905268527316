import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusFaqBlock, FaqBlockEdges } from './faq-block.interface';

export default (): FaqBlockEdges[] => {
    const { allDirectusFaqBlock } = useStaticQuery<AllDirectusFaqBlock>(graphql`
    query {
      allDirectusFaqBlock(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            sort
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            image_alt
            title
            link
          }
        }
      }
    }
  `);

    return allDirectusFaqBlock.edges;
};
