import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import trustpilotBannerHook from 'hooks/trustpilot-banner/trustpilot-banner.hook';
import trustpilotImagesHook from 'hooks/trustpilot-banner/trustpilot-images.hook';

import s from './TrustPilotBanner.scss';

export default (): JSX.Element => {
    const { total, score } = trustpilotBannerHook().review;
    const trustPilotImages = trustpilotImagesHook();
    const stars = trustPilotImages.find(({ node }) => node.title === 'Wide Trust Stars');
    const logo = trustPilotImages.find(({ node }) => node.title === 'Wide Trust Logo');
    const background = trustPilotImages.find(({ node }) => node.title === 'Wide Trust Background');

    return (
        <BackgroundImage
            className={s.trustPilotBanner}
            fluid={background.node.localFile.childImageSharp.fluid}
        >
            <div className={s.trustPilotBanner__innerContent}>
                <div className={s.trustPilotBanner__stars}>
                    <img
                        src={stars.node.data.full_url}
                        alt="star icon"
                    />
                    <span className={s.trustPilotBanner__reviews}>({total} reviews)</span>
                </div>

                <div className={s.trustPilotBanner__logo}>
                    <img
                        src={logo.node.data.full_url}
                        alt="Trustpilot logo"
                    />
                </div>

                <h3 className={s.trustPilotBanner__rating}>
                    Rated <span className={s.trustPilotBanner__rating___score}>{score} out of 5.0 </span> by our customers
                </h3>

                <div className={s.trustPilotBanner__text}>
                    <p>Read unfiltered reviews from people like you</p>
                </div>
                <a
                    className={s.trustPilotBanner__link}
                    href="https://www.trustpilot.co.uk/review/rapidformations.co.uk"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    &nbsp;
                </a>
            </div>
        </BackgroundImage>
    );
};
