import React from 'react';
import { Helmet } from 'react-helmet';
import companyDetailsHook from 'hooks/company-details/company-details.hook';
import trustpilotBannerHook from 'hooks/trustpilot-banner/trustpilot-banner.hook';

export default(): JSX.Element => {
    const company = companyDetailsHook()[ 0 ];
    const { review } = trustpilotBannerHook();

    return (
        <Helmet>
            <script type='application/ld+json'>
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: company.node.company_name,
                    address: {
                        '@type': 'PostalAddress',
                        streetAddress: company.node.company_address,
                        addressLocality: company.node.company_address_locality,
                        postalCode: company.node.company_postcode,
                        addressCountry: company.node.company_country,
                    },
                    url: company.node.url,
                    telephone: company.node.telephone_number,
                    email: company.node.email_address,
                    VatID: company.node.company_vat,
                    aggregateRating: {
                        '@type': 'AggregateRating',
                        ratingValue: review.score,
                        reviewCount: parseInt(review.total.replace(/,/g, ''), 10),
                    },
                    logo: company.node.logo_url,
                })}
            </script>
        </Helmet>
    );
};
