import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusPageTitle, PageTitleEdges } from './page-title.interface';

export default (): PageTitleEdges[] => {
    const { allDirectusPageTitle } = useStaticQuery<AllDirectusPageTitle>(graphql`
    query {
      allDirectusPageTitle {
        edges {
          node {
            page_title
            buy_link
            slug
            upgradable
            vat
            yearly
            price {
              value
            }
            discounted_price {
              value
            }
            buttons
          }
        }
      }
    }
  `);

    return allDirectusPageTitle.edges;
};
