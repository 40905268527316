import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Props } from './FAQDropDownList.interface';

import s from './FAQDropDownList.scss';

export const FAQDropDownList = ({ children, title }: Props): JSX.Element => {
    const [ visible, setIsVisible ] = useState<boolean>(false);
    const toggleDropdown = () => setIsVisible(!visible);
    const state = typeof window !== 'undefined' && window.history ? window.history.state : {};

    useEffect((): void =>
        setIsVisible(false), [ state ],
    );

    const menu = visible ? {
        button: s.dropdown__button___open,
        listStyle: s.list__open,
    } : {
        button: '',
        listStyle: s.list__closed,
    };

    return (
        <div className={s.dropdown}>
            <OutsideClickHandler onOutsideClick={() => setIsVisible(false)}>
                <button
                    onClick={toggleDropdown}
                    className={`${s.dropdown__button} ${menu.button}`}
                    type="button"
                >
                    <h4 className={s.dropdown__title}>{title}</h4>
                </button>

                <ul className={`${s.list} ${menu.listStyle}`}>
                    {children}
                </ul>
            </OutsideClickHandler>
        </div>
    );
};
