import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusFooterCompanyDetail, FooterCompanyDetailEdge } from './company-details.interface';

export default (): FooterCompanyDetailEdge[] => {
    const { allDirectusFooterCompanyDetail } = useStaticQuery<AllDirectusFooterCompanyDetail>(graphql`
      query MyQuery {
        allDirectusFooterCompanyDetail {
          edges {
            node {
              company_address
              company_address_locality
              company_name
              company_number
              company_postcode
              company_country
              company_vat
              email_address
              url
              telephone_number
              logo_url
            }
          }
        }
      }
  `);

    return allDirectusFooterCompanyDetail.edges;
};
