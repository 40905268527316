import React from 'react';
import { Helmet } from 'react-helmet';
import showdown from 'showdown';

import { Props } from './Faqs.interface';

export default({ data }: Props): JSX.Element => {
    const converter = new showdown.Converter();

    return (
        <Helmet>
            <script type='application/ld+json'>
                {JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'FAQPage',
                    mainEntity: [
                        data.map(({ node }: any) => ({
                            '@type': 'Question',
                            name: node.title,
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: converter.makeHtml(node.body),
                            },
                        })),
                    ],
                })}
            </script>
        </Helmet>
    );
};
