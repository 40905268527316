import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import BuyNow from 'components/directus/buy-now/BuyNow';
import faqBlockHook from 'hooks/faq-block/faq-block.hook';
import pageTitleHook from 'hooks/page-title/page-title.hook';
import WindowResizeHook from 'hooks/resize/resize.hook';
import { getFieldBySlug } from 'utils/directus';
import { FAQDropDownList } from './faq-dropdown-list/FAQDropDownList';
import { FAQDropDownListItem } from './faq-list-item/FAQDropDownListItem';
import { Props } from './PageTitle.interface';

import s from './PageTitle.scss';

export default ({ slug, noUnderline, buyService }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, pageTitleHook());
    const isPackage = node.slug.indexOf('package') > -1;
    const faqDropdownPages = faqBlockHook();
    const screenSm = WindowResizeHook().width < 1024 ;

    const mobileTitlesShown: string[] = [ 'confirmation-statement-service-standard', 'certificate-of-good-standing' ];

    if (buyService && screenSm && !mobileTitlesShown.includes(slug)) {
        return <></>;
    }
    return (
        <div className={`${s.pageTitle}`}>
            <div className={s.pageTitle__left}>
                <div className={s.pageTitle__heading}>
                    <h1><Markdown source={node.page_title} /></h1>
                </div>
            </div>
            <div className={s.pageTitle__right}>
                <BuyNow slug={slug} isPackage={isPackage} prices />
                {node.buttons.indexOf('FAQ Dropdown') > -1 && (
                    <FAQDropDownList title="FAQs Quick Navigation">
                        <FAQDropDownListItem to="/faqs/">
                            FAQ Homepage
                        </FAQDropDownListItem>
                        { faqDropdownPages.map(({ node }, i) => (
                            <FAQDropDownListItem
                                key={`faq-dropdown-item-${node.directusId}`}
                                to={node.link}
                            >
                                {node.title}
                            </FAQDropDownListItem>
                        ))}
                    </FAQDropDownList>
                )}
            </div>
            <span className={noUnderline ? '' : s.pageTitle__divider} />
        </div>
    );
};
