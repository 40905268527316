import { graphql, useStaticQuery } from 'gatsby';
import { TrustPilotImages, Image } from './trustpilot-images.interface';

export default (): Image[] => {
    const { allDirectusFile } = useStaticQuery<TrustPilotImages>(graphql`
    query {
      allDirectusFile(filter: { title: { regex: "/Wide Trust/" } }) {
        edges {
          node {
            title
            data {
              full_url
            }
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `);

    return allDirectusFile.edges;
};
