import React from 'react';
import { Helmet } from 'react-helmet';
import priceHook from 'hooks/price/price.hook';
import { Props } from './servicesPage.interface';

export default({ data }: Props): JSX.Element => {
    const { node: price }  = priceHook().filter(price => price.node.page_url === data.slug)[ 0 ];
    return (
        <Helmet>
            <script type='application/ld+json'>
                {JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'Product',
                    name: data.title,
                    image: 'https://d2zkzcdiu38fde.cloudfront.net/images/27548908-3191-4513-a17f-ace3e05da433.webp',
                    description: data.description,
                    offers: {
                        '@type': 'offer',
                        url: data.canonical,
                        priceCurrency: 'GBP',
                        price: price && price.value,
                        availability: 'https://schema.org/InStock',
                    },
                })}
            </script>
        </Helmet>
    );
};
